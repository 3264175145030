<template>
  <div class="chat">
    <div class="axerta-banner-chat">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="pt-4">
          <label class="mr-2">Seleziona il CIE</label>
          <b-form-select
            v-model="idCie"
            @change="getMessages(idDossier, idCie)"
            :options="CIEOptions"
          >
          </b-form-select>
        </div>
        <div v-if="currentCiePhoto" class="axerta-banner-chat-avatar-container">
          <img v-if="currentCiePhoto.length > 0" :src="currentCiePhoto" />
          <img v-else src="../../public/images/avatar_placeholder.png" />
        </div>
        <div v-else-if="idCie" class="axerta-banner-chat-avatar-container">
          <img src="../../public/images/avatar_placeholder.png" />
        </div>
      </div>
    </div>

    <div class="messages-wrapper">
      <div
        v-for="message in messages"
        :key="message.id"
        class="message"
        :class="message.userType == 'B' ? 'my-message' : 'others-message'"
        @contextmenu.prevent="rightclick"
      >
        {{ message.message }}
        <div v-for="file in message.files" :key="file.file_url">
          <template v-if="file.file_url.match(/.(jpg|jpeg|png|gif)$/i)">
            <img style="width: 75%; border-radius: 15px" :src="file.file_url" />
          </template>
          <template v-else
            ><a :href="file.file_url" target="_blank">{{
              file.file_url
            }}</a></template
          >
        </div>
        <div class="footer">
          <span class="datetime">
            <span v-if="loading"> loading...</span>
            <span
              v-else
              class="mr-4 condividi-messaggio"
              :class="message.shared ? 'condiviso' : null"
              role="button"
              @click="toggleSharedMessage(message)"
            >
              {{ message.shared ? "Smetti di condividere" : "Condividi" }}
            </span>
            29/09/22
            <b-icon-check-all
              v-if="message.is_read && message.userType == 'B'"
              class="is-read-true"
            />
            <b-icon-check
              v-else-if="!message.is_read && message.userType == 'B'"
              class="is-read-false"
            />
          </span>
        </div>
      </div>
    </div>

    <div class="current-message d-flex">
      <b-form-textarea
        id="textarea-default"
        placeholder="Type your message..."
        no-resize
        :disabled="isSending"
        autofocus
        v-model="value"
        @keydown.enter.prevent="sendMessage"
      ></b-form-textarea>

      <div
        class="
          ml-3
          d-flex
          flex-column
          align-items-center
          justify-content-between
          py-3
          h4
          m-0
        "
      >
        <b-form-file
          class="button-file attachment"
          v-model="files"
          multiple
          :state="Boolean(files)"
          :disabled="isSending"
        >
          <template #placeholder>
            <b-icon-paperclip />
          </template>
          <template #file-name>
            <b-icon-paperclip />
            <b-badge class="attachment-counter" pill variant="primary">{{
              files.length
            }}</b-badge>
          </template>
        </b-form-file>

        <b-icon-arrow-right-circle-fill
          :disabled="isSending"
          @click="sendMessage"
          class="h2 pointer m-0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "./../axiosServices/AxiosService"

// import { datetimeToString, dateJsToJSON } from "@/utils/DatetimeUtils.js";
export default {
  components: {},
  props: {
    // item: Object,
    idDossier: Number,
    plugin: {
      type: String,
      default: "normal",
    },
  },
  service: null,
  created() {
    this.service = new AxiosService("HelpDeskChat")

    // this.kpiValue = this.item.kpiValue.toString();
  },
  mounted() {
    this.getCieOptions()
    this.timer = setInterval(this.getMessages(), 5000)
  },
  data() {
    return {
      CIEOptions: [],
      kpiValue: 0,
      margineType: "",
      margineValue: "",
      status: null,
      messages: [],
      value: null,
      files: [],
      fileUrl: this.$driveAddress,
      isSending: false,
      marginePerc: null,
      idCie: null,
      timer: null,
      loading: false,
      currentCiePhoto: null,
    }
  },
  methods: {
    toggleSharedMessage(event) {
      this.loading = true
      console.log(
        "🚀 ~ file: ChatAxerta.vue:148 ~ toggleSharedMessage ~ event",
        event
      )
      const service = new AxiosService(
        "DossierManager/ShareMessage/" + event.id
      )
      service
        .putCustomEndpoint("DossierManager/ShareMessage/" + event.id)
        .then((res) => console.log(res))
        .finally(() => {
          this.loading = false
          event.shared = !event.shared
          // this.getMessages(this.idDossier, this.idCie);
        })
    },
    getCieOptions() {
      const service = new AxiosService(
        "DossierManager/GetCieDossier/" + this.idDossier
      )
      service.read().then((res) => {
        this.CIEOptions = res
      })
    },
    getMessages(idDossier, idCie, event) {
      console.log("DossierManager/GetOrderChat_BO/" + idDossier + "/" + idCie)
      this.messages.splice(0)
      const service = new AxiosService(
        "DossierManager/GetOrderChat_BO/" + idDossier + "/" + idCie
      )
      service
        .read()
        .then((res) => {
          this.messages = res
          console.log("get messages , ", res)
          this.messages.forEach((message) => {
            if (message.userType == "U") {
              message.is_read = true
            }
          })
        })
        .finally(() => {
          this.scrollDown()

          this.currentCiePhoto = this.CIEOptions.filter(
            (cie) => cie.value == idCie
          )[0].photo
        })
    },
    closeTicketSalesChat() {
      const obj = { ...this.item }

      obj.kpiValue = this.kpiValue
      obj.margineValue = this.margineValue
      obj.id_status = 3
      obj.solved = true
      obj.need_reply = false

      try {
        // let currentDatetime = new Date();
        let message = {}
        message.id = null
        // message.data = dateJsToJSON(currentDatetime);
        message.message =
          "Ordine confermato. Valore vendite: " +
          this.kpiValue +
          ", valore margine: " +
          this.margineValue
        message.userType = "B"
        message.id_user = 6
        message.files = []
        this.service
          .putCustomEndpoint(
            "SalesChat/CloseOrder/" + this.item.id,
            "",
            message
          )
          .then((response) => {
            if (response.id) {
              message.id = response.id
              this.messages.push(message)
              this.scrollDown()
              this.value = ""
              this.kpiValue = 0
              this.margineValue = ""
              this.files = []
              this.$emit("update-reply")
            } else {
              this.$errorToast()
              return false
            }
          })
          .catch(() => {
            this.$errorToast()
            return false
          })
          .finally(() => {
            this.isSending = false
          })
      } catch {
        this.$errorToast("Ordine non confermato")
      } finally {
        // this.service
        //   .putCustomEndpoint("SalesChat/UpdateOrder", this.item.id, obj)
        //   .then((res) => {
        //     this.$successToast("Ordine confermato");
        //     this.$root.$emit("bv::toggle::collapse", "sidebar-chat");
        //     console.log(res);
        //   });
      }
    },

    scrollDown() {
      let container = this.$el.querySelector(".messages-wrapper")
      this.$nextTick(function() {
        container.scrollTop = container.scrollHeight
      })
    },
    sendMessage() {
      if (this.isSending) {
        return false
      }
      if (!this.value && !this.files.length) {
        return false
      }
      this.isSending = true
      // let currentDatetime = new Date();
      let message = {}
      message.id = null
      // message.data = dateJsToJSON(currentDatetime);
      message.message = this.value
      message.userType = "B"
      message.id_user = 6
      message.files = []

      let promises = []
      for (let i = 0; i < this.files.length; i++) {
        const formData = new FormData()
        formData.append("file", this.files[i])
        promises.push(this.service.uploadFile(formData))
      }
      Promise.all(promises)
        .then((results) => {
          results.forEach((res) => {
            if (res) {
              //   message.files.push({ file_url: this.fileUrl + res.data });
              message.files.push({ file_url: res.data })
            }
          })
        })
        .finally(() => {
          this.service
            .postCustomEndpoint(
              "DossierManager/NewMessage/" + this.idDossier + "/" + this.idCie,
              "",
              message
            )
            .then((response) => {
              if (response.id) {
                message.id = response.id
                this.messages.push(message)
                this.scrollDown()
                this.value = ""
                this.files = []
                this.$emit("update-reply")
              } else {
                this.$errorToast()
                return false
              }
            })
            .catch(() => {
              this.$errorToast()
              return false
            })
            .finally(() => {
              this.isSending = false
            })
        })
    },
    // formatDate(datetime) {
    //   return datetimeToString(datetime);
    // },
    closeTicket() {
      this.$emit("close-ticket")
    },
  },
  computed: {
    computed_margineValue() {
      return this.kpiValue * (this.marginePerc / 100)
    },
  },
}
</script>
