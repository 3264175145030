<template>
	<div class="content-wrapper">
		<div class="d-flex justify-content-between">
			<h1 class="mb-5">Lista Dossier</h1>
			<div style="font-size: 1.5rem">
				<b-badge variant="warning">
					<b-icon-star-fill class="mr-2"></b-icon-star-fill> Plugin
				</b-badge>
			</div>
		</div>
		<b-card class="mb-4">
			<b-row class="my-1 justify-content-between">
				<b-col cols="auto">
					<b-input-group size="sm" class="mb-2">
						<b-input-group-prepend is-text>
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>
						<b-form-input
							id="filter-input"
							v-model="filter"
							type="search"
							placeholder="Search"
						></b-form-input>
					</b-input-group>
				</b-col>
				<!-- <b-col lg="3">
          <b-button size="sm" class="mb-2" v-b-toggle.collapse-advancedFilter :disabled="advancedFilter_visible">
            <b-icon icon="filter" aria-hidden="true"></b-icon> Advanced Filter
          </b-button>
        </b-col> -->

				<b-col cols="3" class="text-lg-right">
					<b-row>
						<b-col>
							<b-button class="mb-2 adriano-small" @click="goToCalendar">
								<b-icon icon="calendar" aria-hidden="true"></b-icon> Vai al
								Calendario
							</b-button>
						</b-col>
						<b-col>
							<b-button class="mb-2 adriano-small" v-b-toggle.sidebar-dossier>
								<b-icon icon="plus" aria-hidden="true"></b-icon> Nuovo Dossier
							</b-button>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-card>
		<b-collapse
			id="collapse-advancedFilter"
			v-model="advancedFilter_visible"
			class="mt-2"
		>
			<!-- <b-card class="mb-4">
        <div class="d-flex">
          <span>Advanced Filter</span>
          <b-button-close v-b-toggle.collapse-advancedFilter class="ml-auto" />
        </div>
         <b-row class="mt-3">
          <b-col>
            <b-form-select
              v-model="companyListSelected"
              :options="filterCompanyList"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              v-model="areaTypeListSelected"
              :options="filterAreaTypeList"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              v-model="departmentTypeListSelected"
              :options="filterDepartmentTypeList"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              v-model="clusterTypeListSelected"
              :options="filterClusterTypeList"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              v-model="roleTypeListSelected"
              :options="filterRoleTypeList"
            ></b-form-select>
          </b-col>
        </b-row> 
      </b-card>-->
		</b-collapse>
		<b-overlay :show="items == null" rounded="sm" class="overlay-placeholder">
			<b-card>
				<b-table
					hover
					:items="items"
					:fields="fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
				>
					<template #cell(ciePhotos)="row">
						<span v-if="row.item.ciePhotos || row.item.ciePhotos.length != 0">
							<div style="width:150px">
								<b-avatar-group size="3rem" overlap="0.5">
									<b-avatar
										v-for="(cie, index) in row.item.ciePhotos"
										:key="index"
										:src="cie"
									></b-avatar>
								</b-avatar-group>
							</div>
						</span>
						<div v-else>
							0
						</div>
					</template>
					<template #cell(elementi)="row">
						<span v-for="elemento in row.item.elementi" :key="elemento.id">
							<b-badge class="mr-2">{{ elemento.tag }}</b-badge>
						</span>
					</template>
					<template #cell(periodo)="row">
						<div style="width: 100px">
							{{
								new Date(row.item.dataInizio).toLocaleDateString("it", {
									year: "2-digit",
									month: "short",
									day: "2-digit",
								})
							}}
						</div>
						<div style="width: 100px">
							{{
								new Date(row.item.dataFine).toLocaleDateString("it-IT", {
									year: "2-digit",
									month: "short",
									day: "2-digit",
								})
							}}
						</div>
					</template>

					<template #cell(azioni)="row">
						<b-dropdown
							size="sm"
							variant="link"
							toggle-class="text-decoration-none"
							no-caret
						>
							<template #button-content>
								<b-icon-gear-fill></b-icon-gear-fill>
							</template>
							<b-dropdown-item
								href="#"
								v-b-toggle.sidebar-dossier
								@click="gotoListaOrdini(row.item.id)"
								>Ordini di servizio</b-dropdown-item
							>
							<b-dropdown-item
								href="#"
								v-b-toggle.sidebar-dossier
								@click="gotoListaRilevazioni(row.item.id)"
								>Timeline</b-dropdown-item
							>
							<b-dropdown-item
								href="#"
								v-b-toggle.sidebar-dossier
								@click="
									currentItem = row.item;
									tmpItem = JSON.parse(JSON.stringify(row.item));
								"
								>Modifica</b-dropdown-item
							>
							<b-dropdown-item
								href="#"
								@click="
									tmpItem = row.item;
									showDeleteModal = true;
								"
								>Elimina</b-dropdown-item
							>
						</b-dropdown>
					</template>
					<template #cell(chat)="row">
						<b-icon
							v-if="row.item.messaggiNonLetti > 0"
							@click="showChatSidebar(row.item.id)"
							class="cursor-pointer"
							icon="chat-dots-fill"
							animation="throb"
							scale="1.5"
						></b-icon>
						<b-icon
							v-else
							@click="showChatSidebar(row.item.id)"
							class="cursor-pointer"
							icon="chat"
						></b-icon>
					</template>
				</b-table>
				<b-pagination
					v-model="currentPage"
					:total-rows="totalRows"
					:per-page="perPage"
					align="fill"
					size="sm"
					class="mt-4 mb-1"
				></b-pagination>
			</b-card>
		</b-overlay>
		<b-sidebar
			class="right-sidebar"
			id="sidebar-dossier"
			right
			shadow
			lazy
			@hidden="resetData"
			:visible="sidebarDossierVisible"
		>
			<div class="content-wrapper my-3 mx-1">
				<h4 v-if="currentItem">
					{{ currentItem.titolo }}
				</h4>
				<h4 v-else>New dossier</h4>
				<b-row class="mb-4">
					<b-col>
						<label class="mr-2">Seleziona la Company</label>

						<b-form-select
							v-model="tmpItem.idCompany"
							:options="companyOptions"
						>
						</b-form-select>
					</b-col>
				</b-row>
				<b-row class="mb-4">
					<b-col>
						<label class="mr-2">Seleziona il DossierManager</label>

						<b-form-select
							:options="dossierManagerOptions"
							v-model="tmpItem.idDossierManager"
						>
						</b-form-select>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group label="Numero Dossier">
							<b-form-input v-model="tmpItem.codice" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group label="Titolo">
							<b-form-input v-model="tmpItem.titolo" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group label="Description">
							<VueEditor class="editor" v-model="tmpItem.descrizione" />
						</b-form-group>
					</b-col>
				</b-row>

				<!-- <b-row>
          <b-col>
            <div class="d-flex flex-column mb-4 ">
              <label>Atto di incarico</label>
              <b-form-file @input="uploadAttoDiIncarico" class="button-file attachment" v-model="attoDiIncarico"
                :disabled="loading">
                <template #placeholder>
                  <b-icon-paperclip />
                </template>
                <template #file-name>
                  <b-icon-paperclip />
                </template>
              </b-form-file>
            </div>
          </b-col>
        </b-row> -->

				<b-row class="mb-4">
					<b-col>
						<span>Allegati</span><br />
						<b-row v-if="tmpItem.elementi.length > 0" class="pt-2">
							<b-col cols="3" style="color: gray">Tag</b-col>
							<b-col cols="3" style="color: gray">Link Maps</b-col>
							<b-col cols="3" style="color: gray">Descrizione</b-col>
							<b-col cols="5" style="color: gray">Allegato</b-col>
						</b-row>
						<b-row
							align-v="center"
							v-for="(elemento, index) in tmpItem.elementi"
							:key="index"
							class="mb-2"
						>
							<b-col cols="4">
								<b-input type="text" v-model="elemento.tag"></b-input>
							</b-col>
							<b-col cols="4">
								<b-input type="text" v-model="elemento.link"></b-input>
							</b-col>
							<!-- <b-col cols="3">
                <b-input type="text" v-model="elemento.descrizione"></b-input>
              </b-col> -->
							<b-col cols="3">
								<b-row>
									<b-col cols="6">
										<b-form-file
											@input="uploadFile(elemento)"
											v-if="elemento.allegati.length == 0"
											class="button-file attachment"
											v-model="allegato"
											multiple
											:state="Boolean(allegato)"
											:disabled="loading"
										>
											<template #placeholder>
												<b-icon-paperclip />
											</template>
											<template #file-name>
												<b-icon-paperclip />
											</template>
										</b-form-file>

										<span v-else-if="elemento.allegati[0].url.length > 0">
											<a
												target="blank"
												:href="allegato.url"
												v-for="allegato in elemento.allegati"
												:key="allegato.id"
												class="mr-2"
												>Apri file</a
											>
										</span>
										<b-input
											v-else
											type="text"
											v-model="elemento.allegati[0].url"
										></b-input>
									</b-col>
									<b-col>
										<b-badge
											v-if="elemento.toUpload && !elemento.delete"
											variant="primary"
											>TO UPLOAD</b-badge
										>
										<b-badge v-if="elemento.delete" variant="danger"
											>TO DELETE</b-badge
										>
									</b-col>
									<b-col>
										<b-icon-trash
											v-if="!elemento.delete"
											@click="toggleToDelete(elemento)"
											class="cursor-pointer"
										></b-icon-trash>
										<b-icon-arrow-counterclockwise
											v-if="elemento.delete"
											@click="toggleToDelete(elemento)"
											class="cursor-pointer"
										></b-icon-arrow-counterclockwise>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
						<button
							@click="addElemento"
							class="
                btn
                button-dashed
                big-button
                btn-secondary btn-lg
                rounded-0
                collapsed
                mt-2
              "
						>
							Aggiungi allegato
						</button>
					</b-col>
				</b-row>
				<!-- <b-row class="mb-4 mt-4">
          <b-col>
            <b-form-file class="button-file attachment" v-model="files" multiple :state="Boolean(files)"
              :disabled="loading">
              <template #placeholder>
                <b-icon-paperclip /> Aggiungi informazione Dossier
              </template>
              <template #file-name>
                <b-icon-paperclip />
                <b-badge class="attachment-counter" pill variant="primary">{{
                    files.length
                }}</b-badge>
              </template>
            </b-form-file>
          </b-col>
        </b-row> -->
				<b-row>
					<b-col>
						<b-form-group label="Start">
							<b-form-datepicker
								v-model="tmpItem.dataInizio"
								:min="new Date()"
								locale="it"
							></b-form-datepicker>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="End">
							<b-form-datepicker
								v-model="tmpItem.dataFine"
								:min="tmpItem.start"
								locale="it"
							></b-form-datepicker>
						</b-form-group>
					</b-col>
				</b-row>
				<div class="mt-5 mb-5 d-flex flex-column align-items-end">
					<b-button
						squared
						variant="primary"
						:disabled="loading || computed_isSecondDateBeforeFirst"
						size="lg"
						class="big-button"
						@click="saveItem"
						>{{ loading ? "Loading..." : "Salva" }}</b-button
					>
					<small style="color:red" v-if="computed_isSecondDateBeforeFirst"
						>La data di fine deve essere successiva a quella di inizio</small
					>
				</div>
			</div>
		</b-sidebar>
		<b-sidebar
			class="right-sidebar"
			id="sidebar-chat"
			right
			shadow
			lazy
			@hidden="resetSidebar"
		>
			<ChatAxerta :idDossier="chatItem"></ChatAxerta>
			<!-- <ChatAxerta
        :item="tmpItem"
        @update-reply="updateReply"
        @close-ticket="closeTicket"
      ></ChatAxerta> -->
		</b-sidebar>
		<b-modal v-model="showDeleteModal" title="Attenzione">
			<div class="w-100 d-flex flex-column">
				<div>
					Sei sicuro di voler eliminare il dossier
					<span class="font-bold">{{ tmpItem.titolo || tmpItem.id }}</span
					>?
				</div>

				<div style="color:red;" class="my-2">
					Attenzione! Eliminando il dossier
					<br />Verranno eliminati TUTTI gli ordini ad esso collegati
				</div>

				<div>
					<b-form-checkbox v-model="doubleCheckEliminazioneDossier">
						Ho capito, confermo che voglio eliminare il dossier
						<span class="font-bold">{{ tmpItem.titolo || tmpItem.id }}</span>
					</b-form-checkbox>
				</div>
			</div>
			<template #modal-footer>
				<b-button
					variant="primary"
					:disabled="!doubleCheckEliminazioneDossier"
					@click="deleteItem(tmpItem.id)"
				>
					Elimina il dossier
				</b-button>
				<b-button @click="resetData">Annulla</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
	import AxiosService from "../../axiosServices/AxiosService";
	import ChatAxerta from "../../components/ChatAxerta.vue";
	import { VueEditor } from "vue2-editor";
	import { isSecondDateBeforeFirst } from "@/utils/DatetimeUtils.js";
	// import { mapGetters } from "vuex";

	export default {
		components: {
			ChatAxerta,
			VueEditor,
		},
		data() {
			return {
				doubleCheckEliminazioneDossier: false,
				chatItem: {},
				attoDiIncarico: [],
				sidebarDossierVisible: false,
				loading: false,
				files: [],
				allegato: [],
				advancedFilter_visible: false,
				currentPage: 1,
				perPage: 10,
				filter: "",
				currentItem: null,
				tmpItem: {
					idDossierManager: 0,
					idCompany: 0,
					Company: "",
					titolo: "",
					descrizione: "",
					dataInizio: "",
					dataFine: "",
					elementi: [],
				},
				sospettati: [],
				items: [],
				fields: [
					// { key: "id", label: "ID" },

					// { key: "idCompany", label: "idCompany" },
					{ key: "codice", label: "Dossier" },
					{ key: "titolo", label: "Titolo" },
					{ key: "Company", label: "Cliente" },
					{ key: "DossierManager", label: "Dossier Manager" },
					{ key: "periodo", label: "Periodo" },
					{ key: "ciePhotos", label: "Numero di CIE coinvolti" },
					{ key: "numeroOrdini", label: "N. Ordini" },
					{ key: "numeroRilevazioni", label: "N. Rilevazioni" },
					// { key: "chat", label: "Chat" },
					{ key: "azioni", label: "Azioni" },
				],
				companyOptions: [],
				dossierManagerOptions: [],
				showDeleteModal: false,
			};
		},
		created() {
			this.getData();
			this.getCompanies();
			this.getDossierManagers();
		},
		methods: {
			showChatSidebar(idDossier) {
				this.chatItem = idDossier;
				this.$root.$emit("bv::toggle::collapse", "sidebar-chat");
			},
			resetSidebar() {
				console.log("reset chat sidebar placeholder");
			},
			uploadAttoDiIncarico(event) {
				console.log(
					"🚀 ~ file: ListaDossier.vue:339 ~ uploadAttoDiIncarico ~ event",
					event
				);
			},
			change() {
				console.log(this.allegato);
			},
			gotoListaOrdini(id) {
				this.$router.push("/axerta/dossier/" + id);
			},
			gotoListaRilevazioni(id) {
				this.$router.push("/axerta/lista-rilevazioni/" + id);
			},
			getData() {
				this.loading = true;
				const service = new AxiosService("DossierManager/GetDossiers");
				service
					.read()
					.then((res) => {
						this.items = res;
					})
					.finally(() => {
						this.loading = false;
					});
			},
			resetData() {
				this.doubleCheckEliminazioneDossier = false;
				this.tmpItem = {
					Company: "",
					codice: null,
					dataInizio: "",
					dataFine: "",
					descrizione: "",
					elementi: [],
					id: 0,
					idCompany: 0,
					idDossierManager: 0,
					titolo: "",
				};
				this.showDeleteModal = false;
			},
			deleteItem(id) {
				this.loading = true;
				this.doubleCheckEliminazioneDossier = false;
				const service = new AxiosService("DossierManager/DeleteDossier");
				service
					.delete(id)
					.then((res) => console.log(res))
					.finally(() => {
						this.showDeleteModal = false;
						this.getData();
						this.loading = false;
					});
			},
			saveItem() {
				this.loading = true;
				if (this.tmpItem.id) {
					const service = new AxiosService("DossierManager/EditDossier");
					service
						.update(this.tmpItem)
						.then((res) => console.log(res))
						.finally(() => {
							this.sidebarDossierVisible = false;
							this.getData();
							this.$root.$emit("bv::toggle::collapse", "sidebar-dossier");
							this.loading = false;
						});
				} else {
					const service = new AxiosService("DossierManager/AddDossier");
					service
						.create(this.tmpItem)
						.then((res) => console.log(res))
						.finally(() => {
							this.sidebarDossierVisible = false;
							this.getData();
							this.$root.$emit("bv::toggle::collapse", "sidebar-dossier");
							this.loading = false;
						});
				}
			},
			addElemento() {
				this.tmpItem.elementi.push({
					tag: "",
					descrizione: "",
					toUpload: true,
					delete: false,
					allegati: [],
				});
			},
			addAllegato(item) {
				item.allegati.push({
					idElements: item.id,
					url: "",
					delete: false,
				});
			},
			goToCalendar() {
				this.$router.push("/axerta/timeline");
			},
			getCompanies() {
				this.loading = true;
				const service = new AxiosService("DossierManager/GetCustomers");
				service
					.read()
					.then((res) => {
						this.companyOptions = res.map((x) => {
							return {
								text: x.ragione_sociale,
								value: x.id,
							};
						});
					})
					.finally(() => {
						this.loading = false;
					});
			},

			getDossierManagers() {
				console.log("get Dossier Managers ");
				this.loading = true;
				const service = new AxiosService("UserBo");
				service
					.read()
					.then((res) => {
						this.dossierManagerOptions = res.map((x) => {
							return {
								text: x.nome + " " + x.cognome,
								value: x.id,
							};
						});
					})
					.finally(() => {
						this.loading = false;
					});
			},

			toggleToDelete(item) {
				item.delete = !item.delete;
			},
			uploadFile(elemento) {
				const service = new AxiosService();
				let promises = [];
				const formData = new FormData();
				formData.append("file", this.allegato[0]);
				promises.push(service.uploadFile(formData));
				Promise.all(promises).then((results) => {
					results.forEach((res) => {
						if (res) {
							elemento.allegati.push({
								url: "https://api-axerta.whappy.it/Drive/" + res.data,
								delete: false,
								idElements: elemento.id ? elemento.id : 0,
							});
						}
					});
				});
			},
		},
		computed: {
			totalRows() {
				return this.items ? this.items.length : 0;
			},
			computed_isSecondDateBeforeFirst() {
				return isSecondDateBeforeFirst(
					this.tmpItem.dataInizio,
					this.tmpItem.dataFine
				);
			},

			// ...mapGetters(["languages", "default_lang", "config"]),
			//     ...mapGetters(["default_lang"]),
			//     ...mapGetters(["config"]),
		},
		watch: {
			filter: {
				handler() {
					this.currentPage = 1;
				},
			},
		},
	};
</script>

<style lang="scss">
	.editor {
		background-color: #fff;
		.ql-editor {
			min-height: 600px;
		}
		.ql-snow {
			&.ql-toolbar,
			&.ql-container {
				border: 1px solid #b6b6b6;
			}
		}
	}

	.adriano-small {
		padding: 0.15rem 0.9rem;
		font-size: 0.875rem;
		line-height: 1.5;
		border-radius: 0.2rem;
	}
</style>
